import React from "react";
import { ManageLibrary } from "./manage-library";
import {
    MediaLibraryConfig,
    LiveStreamLibraryConfig,
    LibraryType,
} from "../../libs";
import { userService } from "../../services";
import * as Styled from "./ManageLibraries.styled";
import { Spinner } from "react-bootstrap";
import { toast } from "react-toastify";

export const ManageLibraries: React.FC = () => {
    const libraryConfigs = [
        {
            title: "Manage Media Library",
            controls: [
                {
                    title: "Fetch By Date",
                    key: "fetchByDate",
                    type: "switch",
                },
                {
                    title: "Filter Controls",
                    key: "filterControls",
                    type: "switch",
                },
                {
                    title: "Rating Controls",
                    key: "ratingControls",
                    type: "switch",
                },
                {
                    title: "Share Controls",
                    key: "shareControls",
                    type: "switch",
                },
                {
                    title: "Show Playlists",
                    key: "showPlaylists",
                    type: "switch",
                },
                {
                    title: "Hide Default VOD",
                    key: "hideDefaultVOD",
                    type: "switch",
                },
                {
                    title: "Media Per Page",
                    key: "mediaPerPage",
                    type: "input-number",
                },
            ],
        },
        {
            title: "Manage Live Stream Library",
            controls: [
                {
                    title: "Tab Controls",
                    key: "tabControls",
                    type: "switch",
                },
                {
                    title: "Share Controls",
                    key: "shareControls",
                    type: "switch",
                },
            ],
        },
    ];

    const [mediaLibraryConfig, setMediaLibraryConfig] =
        React.useState<MediaLibraryConfig>();
    const [liveStreamLibraryConfig, setLiveStreamLibraryConfig] =
        React.useState<LiveStreamLibraryConfig>();
    const [loading, setLoading] = React.useState(false);

    const fetchLibraryConfigs = React.useCallback(async () => {
        setLoading(true);
        const mediaLibraryConfigResp = await userService.getLibraryConfig(
            LibraryType.MediaLibrary,
        );
        const liveStreamLibraryConfigResp = await userService.getLibraryConfig(
            LibraryType.LiveStreamLibrary,
        );
        if (mediaLibraryConfigResp.libraryId)
            setMediaLibraryConfig(mediaLibraryConfigResp);
        else setMediaLibraryConfig({ libraryId: LibraryType.MediaLibrary });

        if (liveStreamLibraryConfigResp.libraryId)
            setLiveStreamLibraryConfig(liveStreamLibraryConfigResp);
        else
            setLiveStreamLibraryConfig({
                libraryId: LibraryType.LiveStreamLibrary,
            });
        setLoading(false);
    }, [setMediaLibraryConfig, setLiveStreamLibraryConfig, setLoading]);

    React.useEffect(() => {
        fetchLibraryConfigs();
    }, [fetchLibraryConfigs]);

    const handleOnSave = React.useCallback(
        async (libraryConfig: MediaLibraryConfig | LiveStreamLibraryConfig) => {
            try {
                if (libraryConfig.libraryId === LibraryType.MediaLibrary)
                    setMediaLibraryConfig(libraryConfig);
                if (libraryConfig.libraryId === LibraryType.LiveStreamLibrary)
                    setLiveStreamLibraryConfig(libraryConfig);
                toast.success("Library Config Updated Successfully", {
                    autoClose: 2000,
                });
            } catch (error) {
                toast.error("Error while updating library config");
            }

            await userService.setLibraryConfig(libraryConfig);
        },
        [],
    );

    return loading ? (
        <Styled.SpinnerWrapper>
            <Spinner animation="border" />
        </Styled.SpinnerWrapper>
    ) : (
        <>
            {mediaLibraryConfig && (
                <ManageLibrary
                    title={libraryConfigs[0].title}
                    controls={libraryConfigs[0].controls}
                    config={mediaLibraryConfig}
                    onSave={handleOnSave}
                />
            )}
            {liveStreamLibraryConfig && (
                <ManageLibrary
                    title={libraryConfigs[1].title}
                    controls={libraryConfigs[1].controls}
                    config={liveStreamLibraryConfig}
                    onSave={handleOnSave}
                />
            )}
        </>
    );
};
